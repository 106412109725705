import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Platform from "../sections/platform";

import Home from "../sections/home";
import ProblemsSolutions from "../sections/problemsSolutions";
import HowTo from "../sections/howTo";
import Roadmap from "../sections/roadmap";
import GetStarted from "../sections/getStarted";
import {Token} from "../sections/token";

const IndexPage = () => (
    <Layout>
        <Seo title="Home" />

        <Home />
        <Platform />
        <ProblemsSolutions />
        <Token />
        <HowTo />
        <Roadmap />
        <GetStarted />
        {/*<p>*/}
        {/*  <Link to="/page-2/">Go to page 2</Link> <br />*/}
        {/*  <Link to="/using-typescript/">Go to "Using TypeScript"</Link>*/}
        {/*</p>*/}
    </Layout>
)

export default IndexPage
