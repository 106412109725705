import React from 'react';
import Button from "../components/Button";

const GetStarted = () => (

    <section id="getStarted" className="px-2 md:px-32 py-10 md:py-20 bg-gradient-get-started bg-no-repeat bg-center">
        <div className="w-2/3 mx-auto text-center bg-gradient-blue-100 p-4 rounded-3xl">
            <p className="text-4xl font-medium text-center text-primary-lighter mb-8">Get Started!</p>

            <p className="text-xl font-medium text-center text-primary-green mb-8">
                Start building your portfolio and share it with your friends
            </p>
            <div className="text-center">
                <a href={`${process.env.GATSBY_MY_APP_URL}/signUp`}>
                    <Button className="bg-gradient-blue-green text-blue-dark hover:text-primary-lighter hover:filter
                    hover:drop-shadow-blue-dark focus:outline-none">
                        <span>Sign In</span>
                    </Button>
                </a>
            </div>
        </div>
    </section>

);

export default GetStarted;
