import React from 'react';
import Button from "../components/Button";
import LaptopPortfolio from "../images/laptop-portfolio.inline.svg";
import LaptopLeague from "../images/laptop-league.inline.svg";
import LaptopDashboard from "../images/laptop-dashboard.inline.svg";
import LaptopStock from "../images/laptop-stock.inline.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const SliderPage = (props) => {

    return (

        <div className="lg:flex">
            <div
                className="pt-10 text-center w-full lg:text-left lg:w-1/2 text-white max-w-lg z-10"> {/*absolute left-0 */}
                <h1 className="text-3xl lg:text-4xl xl:text-4xl font-bold leading-none">
                    {props.title}
                </h1>
                <p className="text-xl lg:text-2xl mt-6 font-light">
                    {props.text}
                </p>
                <p className="mt-8 md:mt-12">
                    {props.image !== 'portfolio' &&
                    <a href={`${process.env.GATSBY_MY_APP_URL}/signUp`}>
                        <Button
                            className="bg-gradient-blue-green text-blue-dark
                            hover:text-primary-lighter hover:filter hover:drop-shadow-blue-dark
                            focus:outline-none">
                            Sign In
                        </Button>
                    </a>
                    }
                </p>
            </div>
            <div className="hidden lg:flex">  {/*w-full items-end justify-end*/}
                {props.image === 'league' && <LaptopLeague style={{width: 860}} key="league-img" />}
                {props.image === 'dashboard' && <LaptopDashboard style={{width: 860}} key="dashboard-img" />}
                {props.image === 'stock' && <LaptopStock style={{width: 860}} key="stock-img" />}
                {props.image === 'portfolio' && <LaptopPortfolio style={{width: 860}} key="portfolio-img" />}
            </div>
        </div>

    )
};

export default SliderPage;
