import React from 'react';

const fil0 = { fill: '#0067B0' };
const fil1 = { fill: '#39D5FF' };

const LogoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="w-40"
    // width="317.712mm"
    // height="84.5207mm"
    version="1.0"
    // style={{
    //   shapeRendering: geometricPrecision,
    //   textRendering: geometricPrecision,
    //   imageRendering: optimizeQuality,
    //   fillRule: evenodd,
    //   clipRule: evenodd
    // }}
    viewBox="0 0 31771.18 8452.07"
    xmlns="http://www.w3.org/1999/xlink"
  >
    <defs />
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_2585965316496">
        <g>
          <path
            style={fil0}
            d="M8872.45 6185.74l427.1 0 0 -1497.4 590.44 0 0 -347.98 -1608.14 0 0 347.98 590.6 0 0 1497.4zm3003.65 0l-414 -593.23c240.01,-102.72 379.59,-305.71 379.59,-582.58 0,-413.83 -308.33,-669.57 -801.29,-669.57l-798.83 0 0 1845.38 427.1 0 0 -514.1 371.73 0 20.97 0 356 514.1 458.73 0zm-466.75 -1175.81c0,200.37 -131.72,321.6 -392.7,321.6l-347.98 0 0 -643.19 347.98 0c260.98,0 392.7,118.61 392.7,321.59zm2270.83 1175.81l448.08 0 -825.05 -1845.38 -421.86 0 -822.58 1845.38 437.58 0 163.5 -395.49 856.83 0 163.5 395.49zm-883.2 -719.71l292.6 -706.59 292.6 706.59 -585.2 0zm1645.83 719.71l838.31 0c603.71,0 1017.55,-363.87 1017.55,-922.69 0,-558.99 -413.84,-922.69 -1017.55,-922.69l-838.31 0 0 1845.38zm427.1 -350.6l0 -1144.18 390.08 0c366.49,0 606.33,218.71 606.33,572.09 0,353.22 -239.84,572.09 -606.33,572.09l-390.08 0zm2296.89 7.87l0 -427.11 856.83 0 0 -332.08 -856.83 0 0 -400.73 970.2 0 0 -342.73 -1394.52 0 0 1845.38 1428.76 0 0 -342.73 -1004.44 0zm2073.09 374.35c527.21,0 782.94,-263.61 782.94,-571.61 0,-677.59 -1072.91,-442.5 -1072.91,-783.1 0,-116.32 97.47,-211.34 350.59,-211.34 163.5,0 340.11,47.51 511.48,145.15l131.71 -324.38c-171.36,-108.13 -408.59,-163.5 -640.57,-163.5 -524.58,0 -777.7,261.14 -777.7,575.2 0,685.47 1072.92,448.24 1072.92,793.59 0,112.88 -102.72,197.25 -355.84,197.25 -221.49,0 -453.47,-79.13 -608.95,-192l-144.99 321.27c163.34,126.47 458.72,213.47 751.32,213.47z"
          />
          <path
            style={fil1}
            d="M20495.64 6185.74l1262.79 0 0 -229.36 -999.19 0 0 -1616.02 -263.6 0 0 1845.38zm1945.63 -229.36l0 -595.85 925.31 0 0 -224.12 -925.31 0 0 -566.69 1038.68 0 0 -229.36 -1302.28 0 0 1845.38 1339.14 0 0 -229.36 -1075.54 0zm2997.59 229.36l276.7 0 -838.31 -1845.38 -260.98 0 -835.69 1845.38 271.46 0 202.99 -461.35 980.84 0 202.99 461.35zm-1091.44 -672.2l398.11 -904.33 397.94 904.33 -796.05 0zm3010.53 339.95c-131.72,84.37 -276.71,118.78 -432.34,118.78 -424.49,0 -732.81,-303.25 -732.81,-709.22 0,-411.38 308.32,-709.22 735.43,-709.22 208.39,0 382.37,65.86 535.23,213.64l163.5 -163.51c-168.74,-187.25 -416.62,-284.73 -709.22,-284.73 -572.09,0 -988.71,398.1 -988.71,943.82 0,545.71 416.62,943.82 983.47,943.82 258.36,0 514.1,-79.13 698.57,-234.6l0 -719.87 -253.12 0 0 601.09zm1637.15 353.38c487.72,0 780.48,-282.11 780.48,-817.34l0 -1049.17 -255.74 0 0 1038.68c0,408.59 -189.88,593.23 -522.12,593.23 -332.08,0 -519.34,-184.64 -519.34,-593.23l0 -1038.68 -263.6 0 0 1049.17c0,535.23 295.22,817.34 780.32,817.34zm1700.55 -250.49l0 -595.85 925.3 0 0 -224.12 -925.3 0 0 -566.69 1038.67 0 0 -229.36 -1302.28 0 0 1845.38 1339.14 0 0 -229.36 -1075.53 0z"
          />
        </g>
        <g>
          <polygon
            style={fil1}
            points="2126.53,980.66 3761.92,980.66 2951.14,2384.87 1315.79,2384.87 "
          />
          <polygon
            style={fil1}
            points="4773.48,2396.95 3955.8,980.66 3145.07,2384.92 3962.74,3801.17 "
          />
          <polygon
            style={fil1}
            points="5792.02,980.61 4156.67,980.61 4967.41,2384.87 6602.76,2384.87 "
          />
          <polygon
            style={fil0}
            points="3843.56,3980.74 3025.89,2564.45 2215.15,3968.71 3032.82,5385 "
          />
          <polygon
            style={fil0}
            points="4773.48,5591.45 3955.8,4175.15 3145.07,5579.42 3962.74,6995.66 "
          />
          <polygon
            style={fil0}
            points="5692.77,3980.74 4875.15,2564.59 4064.36,3968.81 4882.04,5385 "
          />
          <polygon
            style={fil1}
            points="6215.43,616.81 1640.98,616.81 709.8,2229.7 4153.27,8452.07 -0,2230.91 1287.99,0 "
          />
          <polygon
            style={fil1}
            points="3990.54,7902.25 7245.47,2229.75 6224.55,461.4 7955.22,2230.87 "
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LogoIcon;
