import * as React from "react"
// import PropTypes from "prop-types"
// import { Link } from "gatsby"
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from './Button';
import LogoIcon from "./LogoIcon";


const Header = () => (



        <nav className="relative flex flex-wrap items-center justify-between pt-4 pb-8">
            <div className="w-full relative flex justify-between items-center">
                <div className="flex items-center text-2xl">
                    <LogoIcon className="w-4 mr-3 inline-block" />
                </div>
                <div className="hidden md:block">
                    {/*<AnchorLink className="px-4 text-white" href="#home">*/}
                    {/*    Home*/}
                    {/*</AnchorLink>*/}
                    <AnchorLink className="px-4 text-white" href="#platform">
                        Platform
                    </AnchorLink>
                    <AnchorLink className="px-4 text-white" href="#token">
                        Token
                    </AnchorLink>
                    <a href={`${process.env.GATSBY_MY_APP_URL}/signUp`} className="px-4">
                        <Button className="text-primary-lighter border-solid border border-primary-lighter
                                               hover:text-blue-dark hover:bg-primary-lighter focus:outline-none">
                            Sign in
                        </Button>
                    </a>
                    <a href={`${process.env.GATSBY_MY_APP_URL}/login`} className="px-4">
                        <Button className="text-blue-dark bg-primary-lighter
                                               border-solid border border-primary-lighter
                                               border-transparent
                                               hover:bg-transparent
                                               hover:text-primary-lighter
                                               focus:outline-none">
                            Log in
                        </Button>
                    </a>
                </div>
                <div className="md:hidden">
                    <a href={`${process.env.GATSBY_MY_APP_URL}/login`} className="px-4">
                        <Button className="text-blue-dark bg-primary-lighter
                                               border-solid border border-primary-lighter
                                               border-transparent
                                               hover:bg-transparent
                                               hover:text-primary-lighter
                                               focus:outline-none">
                            Log in
                        </Button>
                    </a>
                </div>
                {/*</div>*/}
            </div>
        </nav>

);

export default Header;

