import React from 'react';
import Server from "../images/server.inline.svg";

const HowTo = () => {

    return (

    <section id="howTo" className="px-2 md:px-32 py-10 md:py-20 bg-white-transparent">
        <div className="container mx-auto text-center">
            <p className="text-4xl font-medium text-center text-primary-lighter mb-8">How do I get some TRALE?</p>
            <div className="lg:flex">
                <div className="text-left lg:w-2/3 text-white">

                    <ol className="list-decimal list-inside space-y-1">
                        <li>Register to
                            <a href="https://www.binance.com/en/register?ref=JSN1YL39"
                               target="_blank" rel="noreferrer"
                               className="transition-colors duration-200 text-primary-lighter hover:text-primary dark:hover:text-light hover:underline dark:text-primary-light">
                                &nbsp; Binance.com
                            </a>
                        </li>
                        <li>Buy some some Binance coins: BNB</li>
                        <li>Install the
                            <a href="https://metamask.io/download.html"
                               target="_blank" rel="noreferrer"
                               className="transition-colors duration-200 text-primary-lighter hover:text-primary dark:hover:text-light hover:underline dark:text-primary-light">
                                &nbsp;Metamask wallet
                            </a>
                            {/*or*/}
                            {/*<a href="https://chrome.google.com/webstore/detail/binance-chain-wallet/fhbohimaelbohpjbbldcngcnapndodjp"*/}
                            {/*   target="_blank" rel="noreferrer"*/}
                            {/*   className="transition-colors duration-200 text-primary-lighter hover:text-primary dark:hover:text-light hover:underline dark:text-primary-light">*/}
                            {/*    &nbsp;Binance Chain Wallet*/}
                            {/*</a>*/}
                        </li>
                        <li>
                            Create your wallet
                        </li>
                        <li>Transfer your BNB from Binance to your wallet</li>
                        <li>Go to
                            <a href="https://pancakeswap.finance/swap?outputCurrency=0x85eed1605ccd82c1b1ba8b1530d40c5940b1476f"
                               target="_blank" rel="noreferrer"
                               className="transition-colors duration-200 text-primary-lighter hover:text-primary dark:hover:text-light hover:underline dark:text-primary-light">
                                &nbsp;PancakeSwap
                            </a>, approve the addition of the Token and swap some BNB to TRALE!</li>
                        <li>Login to TradesLeague.com and connect your wallet
                            {/*<ul className="list-disc list-inside space-y-1 pl-8">*/}
                            {/*    <li>Metamask</li>*/}
                            {/*    <li>Binance Chain Wallet</li>*/}
                            {/*    <li>Atomic Wallet</li>*/}
                            {/*</ul>*/}
                        </li>
                    </ol>
                    <br />
                    <p>That’s it, you are all set!</p>
                </div>
                <div className="flex w-full items-center justify-center">
                    <Server />
                </div>
            </div>
        </div>
    </section>

    )
};

export default HowTo;
