import React, {useState} from 'react';
import Tokenomics from "../images/tokenomics.inline.svg";
import Allocation from "../images/circle.inline.svg";

export function Token() {
    const [displayUtilitiesImg, toggleUtilities] = useState(true)
    const [displayAllocationImg, toggleAllocation] = useState(false)

    const displayUtilities = () => {
        toggleUtilities(true);
        toggleAllocation(false);
    };

    const displayAllocation = () => {
        toggleUtilities(false);
        toggleAllocation(true);
    };

    return (
        <section id="token" className="px-2 md:px-32 py-10 md:py-20">
            <div className="container mx-auto text-center">
                <p className="text-4xl font-medium text-center text-primary-lighter mb-8">Tokenomics</p>

                <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
                    <div className="space-y-2 items-start justify-center">
                        <p className="text-base leading-normal text-center text-primary-purple">Token name</p>
                        <p className="text-lg font-medium leading-normal text-center text-white">TradesLeague Token</p>
                    </div>
                    <div className="space-y-2 items-start justify-center">
                        <p className="text-base leading-normal text-center text-primary-purple">Token ticket</p>
                        <p className="text-lg font-medium leading-normal text-center text-white">Trale</p>
                    </div>
                    <div className="space-y-2 items-start justify-center">
                        <p className="text-base leading-normal text-center text-primary-purple">Token type</p>
                        <p className="text-lg font-medium leading-normal text-center text-white">BEP-20 on Binance Smart
                            Chain</p>
                    </div>
                    <div className="space-y-2 items-start justify-center">
                        <p className="text-base leading-normal text-center text-primary-purple">Token supply</p>
                        <p className="text-lg font-medium leading-normal text-center text-white">100,000,000</p>
                    </div>
                </div>

                <div className="flex justify-center space-x-8 mb-8 text-center">
                    <div className={`cursor-pointer
                        transition-all duration-200 transform translate-y-px
                        hover:text-primary-green
                        ${displayUtilitiesImg ? 'text-primary-green border-b-2 border-primary-green' : 'text-primary-purple'}
                        `}
                         onClick={displayUtilities}
                    >
                        Token utilities
                    </div>
                    <div className={`cursor-pointer
                        transition-all duration-200 transform translate-y-px
                        hover:text-primary-green
                        ${displayAllocationImg ? 'text-primary-green border-b-2 border-primary-green' : 'text-primary-purple'}
                        `}
                         onClick={displayAllocation}
                    >
                        Token allocation
                    </div>
                </div>

                <div className={`${displayUtilitiesImg ? 'block' : 'hidden'}`}>
                    <Tokenomics className="w-full" />
                </div>
                <div className={`${displayAllocationImg ? 'block' : 'hidden'}`}>
                    <Allocation className="w-full" />

                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mt-16">
                        <div className="inline-flex space-x-2.5 items-center justify-end">
                            <div className="w-8 h-8 rounded-full bg-primary-lighter" />
                            <p className="text-base font-medium text-white">Platform Users & Rewards</p>
                        </div>
                        <div className="inline-flex space-x-2.5 items-center justify-end">
                            <div className="w-8 h-8 rounded-full bg-primary-purpleDark" />
                            <p className="text-base font-medium text-white">Founders & Team</p>
                        </div>
                        <div className="inline-flex space-x-2.5 items-center justify-end">
                            <div className="w-8 h-8 rounded-full bg-primary-green" />
                            <p className="text-base font-medium text-white">Development</p>
                        </div>
                        <div className="inline-flex space-x-2.5 items-center justify-end">
                            <div className="w-8 h-8 rounded-full bg-primary-pink" />
                            <p className="text-base font-medium text-white">Marketing & Growth</p>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

