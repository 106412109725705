import React from 'react';

const Roadmap = () => (

    <section id="roadmap" className="px-2 md:px-32 py-10 md:py-20">
        <div className="container mx-auto text-center">
            <p className="text-4xl font-medium text-center text-primary-lighter mb-8">Roadmap</p>

            <div className="container">
                <div className="flex flex-col md:grid grid-cols-9 mx-auto p-2 ">
                    {/*left*/}
                    <div className="flex flex-row-reverse md:contents">
                        <div className="col-start-1 col-end-5 p-4 rounded-3xl my-4 ml-auto">
                            <p className="text-xl font-medium leading-normal text-primary-green"></p>
                            <div className="leading-tight text-justify text-white">
                                <ul className="list-none list-inside space-y-1 pl-4 pt-4">
                                    <li className="checkmark"><span className="text-white ml-8">Platform design</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Prototype design</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Back-end implementation</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Stocks and Cryptocurrencies API integration</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Social features creation</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Portfolio importation</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Dashboards creation</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Test phase</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                            <div className="h-full w-6 flex items-center justify-center">
                                <div className="h-full w-px bg-primary-green pointer-events-none"></div>
                            </div>
                            <div
                                className="w-6 h-6 absolute top-1/2 -mt-3 ml-5 md:-ml-12 rounded-full"
                            >
                                <div className="diamond"><span className="font-semibold">Q1’21</span></div>
                            </div>
                        </div>
                    </div>
                    {/* right */}
                    <div className="flex md:contents">
                        <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                            <div className="h-full w-6 flex items-center justify-center">
                                <div className="h-full w-px bg-gradient-to-b from-primary-green to-white pointer-events-none"></div>
                            </div>
                            <div className="w-6 h-6 absolute top-1/2 -mt-3 ml-5 rounded-full">
                                <div className="diamond"><span className="font-semibold">Q2’21</span></div>
                            </div>
                        </div>
                        <div className="col-start-6 col-end-10 p-4 rounded-3xl my-4 mr-auto">
                            <h3 className="font-semibold text-lg mb-1 text-primary-green"></h3>
                            <div className="leading-tight text-justify">
                                <ul className="list-none list-inside space-y-1 pl-4">
                                    <li className="checkmark"><span className="text-white ml-8">Whitepaper creation</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Launch on Binance Smart Chain</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Token implementation</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Wallets integration</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">PancakeSwap listing</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Logo creation</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Design 1.0 of the platform</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Landing Page creation and live</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Token release plan and distribution</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Social media: Telegram group, Twitter account</span></li>
                                    <li className="checkmark"><span className="text-white ml-8">Private testing</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* left */}
                    <div className="flex flex-row-reverse md:contents">
                        <div className="col-start-1 col-end-5 p-4 rounded-3xl my-4 ml-auto">
                            <h3 className="font-semibold text-lg mb-1 text-primary-green"></h3>
                            <div className="leading-tight text-justify">
                                <ul className="list-disc list-inside space-y-1 pl-4">
                                    <li className="text-primary-pink"><span className="text-white">Forum creation</span></li>
                                    <li className="text-primary-pink"><span className="text-white">Staking function for the token</span></li>
                                    <li className="text-primary-pink"><span className="text-white">Voting section on the platform</span></li>
                                    <li className="text-primary-pink"><span className="text-white">Marketing campaigns</span></li>
                                    <li className="text-primary-pink"><span className="text-white">Chat room for discussions with friends</span></li>
                                    <li className="text-primary-pink"><span className="text-white">Exchanges listing</span></li>
                                    <li className="text-primary-pink"><span className="text-white">HODL features</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                            <div className="h-full w-6 flex items-center justify-center">
                                <div className="h-full w-px bg-gradient-to-b from-white to-primary-pink pointer-events-none"></div>
                            </div>
                            <div className="w-6 h-6 absolute top-1/2 -mt-3 ml-5 md:-ml-12 rounded-full">
                                <div className="diamond pink"><span className="font-semibold">Q3’21</span></div>
                            </div>
                        </div>
                    </div>
                    {/* right */}
                    <div className="flex md:contents">
                        <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                            <div className="h-full w-6 flex items-center justify-center">
                                <div className="h-full w-px bg-primary-pink pointer-events-none"></div>
                            </div>
                            <div className="w-6 h-6 absolute top-1/2 -mt-3 ml-5 rounded-full">
                                {/*<div className="diamond pink"><span className="roadmap font-semibold">Q4’21</span></div>*/}
                                <div className="diamond pink"><span className="font-semibold">Q4’21</span></div>
                            </div>
                        </div>
                        <div className="col-start-6 col-end-10 p-4 rounded-3xl my-4 mr-auto">
                            <h3 className="font-semibold text-lg mb-1 text-primary-green"></h3>
                            <div className="leading-tight text-justify">
                                <ul className="list-disc list-inside space-y-1 pl-4">
                                    <li className="text-primary-pink"><span className="text-white">Platform design V2.0</span></li>
                                    <li className="text-primary-pink"><span className="text-white">Comments section under stock</span></li>
                                    <li className="text-primary-pink"><span className="text-white">Daily competitions for token holders with token rewards</span></li>
                                    <li className="text-primary-pink"><span className="text-white">Launch of the mobile App</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

);

export default Roadmap;
