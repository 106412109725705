import React from 'react';
import Correct from "../images/correct.inline.svg";
import Incorrect from "../images/incorrect.inline.svg";

const ProblemsSolutions = () => (
    <section id="problemsSolutions" className="px-2 md:px-32 py-10 md:py-20 bg-white-transparent">
        <div className="mx-auto text-center">


            <p className="text-4xl font-medium text-center text-primary-lighter mb-8">Problems & Solutions</p>

            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-8">
                <div
                    className="flex space-x-8 items-center justify-start py-8 pl-8 pr-16 bg-gradient-red rounded-3xl">
                    <div className="inline-flex flex-col space-y-96 items-center justify-center w-9">
                        <Incorrect />
                    </div>
                    <p className="text-base leading-normal text-white">Friends use different trading platforms.</p>
                </div>

                <div
                    className="flex space-x-8 items-center justify-start py-8 pl-8 pr-16 bg-gradient-green rounded-3xl">
                    <div className="inline-flex flex-col space-y-96 items-center justify-center w-9">
                        <Correct />
                    </div>
                    <p className="text-base leading-normal text-white">One platform to share your trades and portfolios
                        with all your friends.<br /></p>
                </div>

                <div
                    className="flex space-x-8 items-center justify-start py-8 pl-8 pr-16 bg-gradient-red rounded-3xl">
                    <div className="inline-flex flex-col space-y-96 items-center justify-center w-9">
                        <Incorrect />
                    </div>
                    <p className="text-base leading-normal text-white">Financial websites leave users feel overwhelmed
                        with information and hard to navigate.</p>
                </div>

                <div
                    className="flex space-x-8 items-center justify-start py-8 pl-8 pr-16 bg-gradient-green rounded-3xl">
                    <div className="inline-flex flex-col space-y-96 items-center justify-center w-9">
                        <Correct />
                    </div>
                    <p className="text-base leading-normal text-white">Clean and modern design with the community
                        feedback on stocks and a place to exchange on your latest trades’ ideas.</p>
                </div>

                <div
                    className="flex space-x-8 items-center justify-start py-8 pl-8 pr-16 bg-gradient-red rounded-3xl">
                    <div className="inline-flex flex-col space-y-96 items-center justify-center w-9">
                        <Incorrect />
                    </div>
                    <p className="text-base leading-normal text-white">No incentives to share good trades and high
                        performing portfolios.</p>
                </div>

                <div
                    className="flex space-x-8 items-center justify-start py-8 pl-8 pr-16 bg-gradient-green rounded-3xl">
                    <div className="inline-flex flex-col space-y-96 items-center justify-center w-9">
                        <Correct />
                    </div>
                    <p className="text-base leading-normal text-white">Get rewarded with our Tokens every time someone
                        outside your friends’ circle is following you.</p>
                </div>

                <div
                    className="flex space-x-8 items-center justify-start py-8 pl-8 pr-16 bg-gradient-red rounded-3xl">
                    <div className="inline-flex flex-col space-y-96 items-center justify-center w-9">
                        <Incorrect />
                    </div>
                    <p className="text-base leading-normal text-white">Financial websites don’t
                        cover crypto currencies.</p>
                </div>

                <div
                    className="flex space-x-8 items-center justify-start py-8 pl-8 pr-16 bg-gradient-green rounded-3xl">
                    <div className="inline-flex flex-col space-y-96 items-center justify-center w-9">
                        <Correct />
                    </div>
                    <p className="text-base leading-normal text-white">Our platform allows to track hundreds of
                        different crypto currencies.</p>
                </div>
            </div>
        </div>
    </section>
);

export default ProblemsSolutions;
