import React from 'react';
import Header from "../components/header";
import {graphql, useStaticQuery} from "gatsby";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import SliderPage from "../components/sliderPage";
// import {GatsbyImage, getImage} from "gatsby-plugin-image";

const Home = () => {

    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


    // const query = graphql`
    //   query {
    //     file(relativePath: { eq: "src/images/4-mackbook.png" }) {
    //       childImageSharp {
    //         # Specify the image processing specifications right in the query.
    //         # Makes it trivial to update as your page's design changes.
    //         sizes(maxWidth: 860 ) {
    //           ...GatsbyImageSharpSizes_tracedSVG
    //         }
    //       }
    //     }
    //   }
    // `
    //
    // const image = getImage(query.file)

    return (
        // <div className="bg-gradient-home bg-no-repeat bg-right-bottom" style={{minHeight: '750px'}}>
        <>
        <header className="px-2 pt-2 sm:px-4 lg:px-32 bg-gradient-home bg-no-repeat bg-right-bottom">
            <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

            <section id="home" className="py-10 lg:py-20">
                <div className="container mx-auto text-center">
                    <Carousel autoPlay={true} infiniteLoop={true} interval={5000} showThumbs={false}
                              showStatus={false} showArrows={false} useKeyboardArrows={true}>

                        <SliderPage title="The easy way to share your stock and crypto portfolios with your friends."
                                    text="Share your buying and selling tips for stocks and crypto and see your friends’ portfolios."
                                    image="dashboard"
                                    key="dashboard-home"/>

                        <SliderPage title="Compete with the community to climb the Leaderboards."
                                    text="Compare your performance versus the Trade League’s members and level up your investments to reach the top leaderboards."
                                    image="league"
                                    key="league-home" />

                        <SliderPage title="Get access to stocks information"
                                    text="From 70+ stock exchanges all over the world and +500 cryptocurrencies."
                                    image="stock"
                                    key="stock-home" />

                        <SliderPage title="Get awesome token rewards when people follow you."
                                    text=""
                                    image="portfolio"
                                    key="portfolio-home" />
                    </Carousel>
                </div>
            </section>
        </header>
        </>
        // </div>
    )
};

export default Home;
