import React from 'react';
import Create from "../images/create.inline.svg";
import Reading from "../images/reading-book.inline.svg";
import Trophy from "../images/trophy.inline.svg";
import Share from "../images/share-1.inline.svg";

const Platform = () => (
    <section id="platform" className="px-2 md:px-32 py-10 md:py-20">
        <div className="mx-auto text-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                <div className="inline-flex flex-col space-y-3.5 items-center justify-start">
                    <div
                        className="flex items-center justify-center p-4 bg-gradient-blue-100 rounded-3xl">
                        <Create />
                    </div>
                    <p className="text-2xl font-medium text-center text-primary-lighter">Create</p>
                    <p className="text-base leading-normal text-center text-primary-purple max-w-lg mx-auto">Build up your portfolio, follow
                        your
                        performances, learn about stocks and how to create a high-performance portfolio in a fun way
                        based
                        on people you are following and comments you read
                    </p>
                </div>
                <div className="inline-flex flex-col space-y-3.5 items-center justify-start">
                    <div
                        className="flex items-center justify-center p-4 bg-gradient-blue-100 rounded-3xl">
                        <Reading />
                    </div>
                    <p className="text-2xl font-medium text-center text-primary-lighter">Learn</p>
                    <p className="text-base leading-normal text-center text-primary-purple max-w-lg mx-auto">Get information from 70+ stock
                        exchanges all over the world, 20,000+ ETFs and +500 cryptocurrencies
                    </p>
                </div>
                <div className="inline-flex flex-col space-y-3.5 items-center justify-start">
                        <div
                            className="flex items-center justify-center p-4 bg-gradient-blue-100 rounded-3xl">
                            <Share />
                        </div>
                    <p className="text-2xl font-medium text-center text-primary-lighter">Share</p>
                    <p className="text-base leading-normal text-center text-primary-purple max-w-lg mx-auto">You can easily share your tips,
                        opinions, portfolio, and build a new sense of connection with your friends and followers<br />
                    </p>
                </div>
                <div className="inline-flex flex-col space-y-3.5 items-center justify-start">
                    <div
                        className="flex items-center justify-center p-4 bg-gradient-blue-100 rounded-3xl">
                        <Trophy />
                    </div>
                    <p className="text-2xl font-medium text-center text-primary-lighter">Win</p>
                    <p className="text-base leading-normal text-center text-primary-purple max-w-lg mx-auto">We are building a platform that
                        allows
                        our members to get rewards: be in the top leaderboard, got subscriptions from followers and earn
                        tokens when members follow your portfolio
                    </p>
                </div>
            </div>

        </div>

    </section>
);

export default Platform;
